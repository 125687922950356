code[class*='language-'],
pre[class*='language-'] {
    text-align: left;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
    color: #c3cee3;
    background: #263238;
    font-family: Roboto Mono, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 1em;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

code[class*='language-']::-moz-selection,
pre[class*='language-']::-moz-selection,
code[class*='language-'] ::-moz-selection,
pre[class*='language-'] ::-moz-selection {
    background: #000000;
}

code[class*='language-']::selection,
pre[class*='language-']::selection,
code[class*='language-'] ::selection,
pre[class*='language-'] ::selection {
    background: #000000;
}

:not(pre) > code[class*='language-'] {
    white-space: normal;
    border-radius: 0.2em;
    padding: 0.1em;
}

pre[class*='language-'] {
    overflow: auto;
    position: relative;
    padding: 12px;
    border-radius: 4px;
}

.language-css > code,
.language-sass > code,
.language-scss > code {
    color: #fd9170;
}

[class*='language-'] .namespace {
    opacity: 0.7;
}

.token.plain-text {
    color: #c3cee3;
}

.token.atrule {
    color: #c792ea;
}

.token.attr-name {
    color: #ffcb6b;
}

.token.attr-value {
    color: #c3e88d;
}

.token.attribute {
    color: #c3e88d;
}

.token.boolean {
    color: #c792ea;
}

.token.builtin {
    color: #ffcb6b;
}

.token.cdata {
    color: #80cbc4;
}

.token.char {
    color: #80cbc4;
}

.token.class {
    color: #ffcb6b;
}

.token.class-name {
    color: #82aaff;
}

.token.color {
    color: #f2ff00;
}

.token.comment {
    color: #546e7a;
}

.token.constant {
    color: #c792ea;
}

.token.deleted {
    color: #f07178;
}

.token.doctype {
    color: #546e7a;
}

.token.entity {
    color: #f07178;
}

.token.function {
    color: #c792ea;
}

.token.hexcode {
    color: #f2ff00;
}

.token.id {
    color: #c792ea;
    font-weight: bold;
}

.token.important {
    color: #c792ea;
    font-weight: bold;
}

.token.inserted {
    color: #80cbc4;
}

.token.keyword {
    color: #c792ea;
    font-style: italic;
}

.token.number {
    color: #fd9170;
}

.token.operator {
    color: #89ddff;
}

.token.prolog {
    color: #546e7a;
}

.token.property {
    color: #80cbc4;
}

.token.pseudo-class {
    color: #c3e88d;
}

.token.pseudo-element {
    color: #c3e88d;
}

.token.punctuation {
    color: #89ddff;
}

.token.regex {
    color: #f2ff00;
}

.token.selector {
    color: #f07178;
}

.token.string {
    color: #c3e88d;
}

.token.symbol {
    color: #c792ea;
}

.token.tag {
    color: #f07178;
}

.token.unit {
    color: #f07178;
}

.token.url {
    color: #fd9170;
}

.token.variable {
    color: #f07178;
}
